<template>
  <b-card no-body>
    <b-card-body class="pb-0">
      <b-row>
        <b-col
          sm="12"
          md="4"
          :class="IS_MOBILE() ? 'px-0' : ''"
        >
          <user-search
            v-model="form.userId"
            :placeholder="userPlaceholder"
            :multiple="multipleUserSelect"
            @input="$emit('input', form)"
          />
        </b-col>
        <b-col
          sm="12"
          md="4"
          class="d-flex justify-content-between"
          :class="IS_MOBILE() ? 'px-0' : ''"
        >
          <template v-if="dateType === 'range'">
            <b-form-datepicker
              v-model="form.start_date"
              placeholder="Select Date"
              calendar-width="100%"
              class="mb-2"
              :max="form.end_date"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              @input="$emit('input', form)"
            />
            <b-form-datepicker
              v-model="form.end_date"
              placeholder="Select Date"
              calendar-width="100%"
              class="mb-2 ml-1"
              :min="form.start_date"
              :max="today"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              @input="$emit('input', form)"
            />
          </template>
          <template v-else-if="dateType == 'month'">
            <b-form-input
              v-model="form.month_start"
              type="month"
              onfocus="this.showPicker()"
              :min="minMonth"
              :max="form.month_end"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              class="mb-2 col-6"
              @input="$emit('input', form)"
            />
            <b-form-input
              v-model="form.month_end"
              type="month"
              onfocus="this.showPicker()"
              class="mb-2 ml-1 col-6"
              :min="form.month_start"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              @input="$emit('input', form)"
            />
          </template>
          <template v-else-if="dateType === 'hidden'">
            <slot name="disabledData" />
          </template>
          <template v-else>
            <div>
              <b-button
                variant="flat-success"
                class="btn-icon p-0"
                @click="subtractDay"
              >
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="25"
                />
              </b-button>
            </div>
            <b-form-datepicker
              id="datepicker-full-width"
              v-model="form.date"
              placeholder="Select Date"
              calendar-width="100%"
              class="mb-2"
              :max="today"
              :date-format-options="IS_MOBILE() ? COMMON.MOBILE_DATE_PICKER_FORMAT : COMMON.DATE_PICKER_FORMAT"
              @input="$emit('input', form)"
            />
            <div
              v-if="TODAY({ localTime: false }) !== form.date"
            >
              <b-button
                variant="flat-success"
                class="btn-icon p-0"
                @click="addDay"
              >
                <feather-icon
                  icon="ChevronRightIcon"
                  size="25"
                />
              </b-button>
            </div>
          </template>
        </b-col>
        <b-col
          v-if="!exclude.includes('timezone')"
          sm="12"
          md="4"
          class="pb-2"
          :class="IS_MOBILE() ? 'px-0' : ''"
        >
          <v-select
            :value="form.time_zone"
            :options="timeZones"
            :reduce="item => item.value"
            label="title"
            :clearable="false"
            @input="updateTimezone"
          >
            <template #option="{ title }">
              <span> {{ title }}</span>
            </template>
          </v-select>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>
<script>
import vSelect from 'vue-select'
import {
  BCard, BCardBody, BFormDatepicker, BButton, BFormInput,
}
from 'bootstrap-vue'
import UserSearch from '@/views/common/components/UserSearch.vue'
import useApollo from '@/plugins/graphql/useApollo'
import moment from 'moment'
import EventBus from '@/event-bus'

export default {
  components: {
    BCard,
    UserSearch,
    BCardBody,
    BFormDatepicker,
    BFormInput,
    vSelect,
    BButton,
  },
  props: {
    value: { // date  userID
      type: Object,
      required: true,
    },
    exclude: {
      type: Array,
      default: () => [],
    },
    dateType: {
      type: String,
      default: 'single',
    },
    userPlaceholder: {
      type: String,
      default: 'Me',
    },
    multipleUserSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {
        date: this.value.date,
        userId: this.value.userId,
        time_zone: this.value.time_zone,
        start_date: this.value.start_date,
        end_date: this.value.end_date,
        month_start: this.value.month_start,
        month_end: this.value.month_end,
        today: this.value.today,
      },
      timeZones: [],
    }
  },
  computed: {
    minMonth() {
      return moment().subtract('1', 'year').format('YYYY-MM')
    },
    today() {
      return this.TODAY({ localTime: false })
    },
  },
  watch: {
    'value.start_date': {
      handler(val) {
        this.form.start_date = val
      },
      immediate: true,
    },
  },
  mounted() {
    this.getCompanyTimezones()
    EventBus.$on('timezones-updated', () => {
      this.getCompanyTimezones()
    })
  },
  methods: {
    getCompanyTimezones() {
      useApollo.company.getMyCompanyTimeZone({ companyUid: this.$store.state.project.selectedCompany }).then(response => {
        const company = response.data.me.companies.data[0]
        const defaultTimezone = company.timezoneOffset
        const reportTimezone = company.timezones.data.filter(timezone => timezone.name !== defaultTimezone.name)
        this.timeZones = [
          {
            title: defaultTimezone.displayName,
            value: defaultTimezone.name,
            filter: defaultTimezone.filter,
          },
          ...reportTimezone.map(rt => ({ title: rt.displayName, value: rt.name, filter: rt.filter })),
        ]
        this.$store.dispatch('project/setTimezones', this.timeZones)
      }).catch(error => {
        console.log(error)
      })
    },
    addDay() {
      this.form = {
        ...this.form,
        date: moment(this.form.date).add(1, 'days').format('YYYY-MM-DD'),
      }
      this.$emit('input', this.form)
    },
    subtractDay() {
      this.form = {
        ...this.form,
        date: moment(this.form.date).subtract(1, 'days').format('YYYY-MM-DD'),
      }
      this.$emit('input', this.form)
    },
    updateTimezone(newValue) {
      this.$store.dispatch('project/STORE_SELECTED_TIMEZONE', newValue).then(() => {
        const timeZone = this.timeZones.find(zone => zone.value === newValue)
        this.$store.dispatch('project/STORE_SELECTED_TIMEZONE_FILTER', timeZone).then(() => {
          this.form = {
            ...this.form,
            time_zone: newValue,
          }
          this.$emit('input', this.form)
          this.$emit('timezone-changed')
        })
      })
    },
  },

}
</script>
